.unit-page-layout-v1 {
	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.unit-calendar {
		@media (min-width: 1201px) and (max-width: 1500px) {
			.asd__wrapper {
				width: 710px !important;

				.asd__month {
					width: 242px !important;
				}
			}

			.asd__day {
				line-height: 28px !important;
				height: 25px !important;
			}

			.asd__days-legend {
				width: 240px !important;
				top: 45px !important;
			}

			.asd__days-legend:nth-child(3) {
				left: 0px !important;
			}

			.asd__days-legend:nth-child(4) {
				left: 238px !important;
			}

			.asd__days-legend:nth-child(5) {
				left: 479px !important;
			}

			.asd__inner-wrapper {
				margin-left: -250px !important;
			}

			.asd__month-name {
				font-size: 1rem !important;
			}

			.asd__day-button {
				font-size: 13px;
			}
		}

	}


	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 80px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}