.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-bg3.jpg);
	margin-bottom: -100px;
	padding-bottom: 100px;


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: relative;
	color: var(--color-primary);
	padding: 0.9375rem 0.625rem 3rem 0.625rem;

	h2 {
		color: var(--color-primary);
	}


	&:before {
		background-color: rgba(225, 219, 212, 0.7);

		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: "";
	}

}