/*.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}*/

footer {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: var(--color-primary);
	text-align: center;
	margin-top: 100px;

	p {
		color: var(--color-white);
	}

	ul {
		padding: 0;
		margin: 5px 0;
		color: var(--color-white);
		display: block;

		&.bullets {
			li+li {
				position: relative;
				padding-left: 15px;

				&:before {
					content: "";
					width: 5px;
					height: 5px;
					background-color: var(--color-white);
					position: absolute;
					left: 0;
					border-radius: 50%;
					top: 11px;
				}
			}
		}

		li {
			list-style: none;
			display: inline-block;
			padding: 0 5px;
			font-size: 16px;

			a {
				color: var(--color-white) !important;

				&:hover {
					text-decoration: underline;
				}
			}

			i {
				font-size: 14px;
				margin-right: 3px;
			}
		}
	}



	.social-links {
		text-align: center;
		padding-top: 20px;

		a {
			line-height: 2.125rem;
			background-color: var(--color-white);
			padding: 0.375rem;
			border-radius: 4px;
			width: 2.8125rem;
			height: 2.8125rem;
			font-size: 1.875rem;
			text-align: center;
			display: inline-block;

			&:hover {
				text-decoration: none;
				color: var(--color-secondary);
			}
		}
	}

}

.copyright {
	background-color: var(--color-secondary);
	padding: 15px 0;
	color: var(--color-white);

	@media (max-width:991px) {
		text-align: center;
	}

	.links {
		margin: 0;


		@media (min-width:991px) {
			text-align: right;
		}

		li {
			margin-left: 10px;
			display: inline-block;

			a {
				font-size: 16px;
				color: var(--color-white);

				&:hover {
					color: #006bc7;
					text-decoration: none;
				}
			}
		}
	}

	.v-office-logo {
		text-align: right;
		padding: 10px 0;

		@media (max-width:991px) {
			text-align: center;
		}
	}
}

.newslatter-wraper {
	margin-top: -100px;
	position: relative;
	margin-bottom: 50px;
	padding: 0 15px;

	.headline {
		color: var(--color-white);
	}

	.newslatter-col {
		background-color: var(--color-orange);
		padding: 15px;
		border-radius: 10px;
		max-width: 1320px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		color: var(--color-white);

		.input-bl {
			background-color: var(--color-white);

			color: var(--white);
			border-radius: 30px;
			border: 4px solid var(--color-white);
			padding-left: 15px;

			display: flex;
			max-width: 420px;
			margin: 0 auto;

			input {
				background: var(--white);
				height: 100%;
			}

			.btn-link {
				padding: 12px 40px;
				border-radius: 30px;
				color: #fff;
				background-color: var(--color-primary);

				@media (max-width:767px) {
					padding: 12px 15px;
				}

				&:hover {
					background: #387fc5;
					text-decoration: none;
				}
			}
		}

		.nl-answer {
			color: var(--color-white);
		}
	}
}