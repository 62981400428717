.unit-pricelist-v1 {
	position: relative;

	.showLess {
		.table-responsive {
			height: 0px;
			overflow: hidden;
		}

		&.showFull {
			.table-responsive {
				height: 100%;
				overflow: visible;
			}
		}
	}


	.pricelist {
		.mainrow {
			display: none;


			&:nth-child(1),
			&:nth-child(2) {
				display: table-row;
			}

			/*&:nth-child(1) {
				td {
					border-top: none !important;
				}
			}*/

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}


	}

	.hide-show-btn {
		position: absolute;
		bottom: -14px;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 5px 15px;
		background-color: var(--white);
		display: inline-block;
		border: 1px solid var(--btn-border-color-hide-show);
		font-size: var(--font-size-md);

		&:focus,
		&:hover {
			text-decoration: none;
		}

		&:after {
			content: "\f107";
			margin-left: 10px;
			margin-left: 5px;
			font-family: fontawesome;
		}
	}



	.showFull {
		.pricelist {
			.mainrow {
				display: table-row;
				width: 100%;

			}
		}
	}
}