@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

/* barlow-semi-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* parisienne-regular - latin-ext_latin */
@font-face {
	font-family: 'Parisienne';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open sans */
@font-face {
	font-family: 'Open Sans';
	src: local(''),
		url('RESOURCE/fonts/OpenSans-Bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/OpenSans-Bold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local(''),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local(''),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/OpenSans-SemiBold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

/* Catamaran */
@font-face {
	font-family: 'Catamaran';
	src: local(''),
		url('RESOURCE/fonts/Catamaran-Bold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Catamaran-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Catamaran';
	src: local(''),
		url('RESOURCE/fonts/Catamaran-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Catamaran-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Catamaran';
	src: local(''),
		url('RESOURCE/fonts/Catamaran-SemiBold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Catamaran-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Caveat';
	src: local(''),
		url('RESOURCE/fonts/Caveat-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Caveat-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}