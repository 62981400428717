.index-slide-show-v1 {
	position: relative;
	margin-top: 230px;

	@media (max-width:1200px) {
		margin-top: 120px;
	}




	.owl-carousel {
		z-index: unset;
	}

	.banner-search {
		position: relative;
		padding: 30px 0;
	}


	.index-slide {

		margin-top: var(--index-slide-show-margin-top);

		.item {

			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {

			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			/*		padding-top: 45%;*/
			position: relative;
			padding-top: 290px;

			@media (max-width:767px) {
				/*padding-top: 55%;*/
				padding-top: 180px;
			}


			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.banner-search {
		background-color: rgba(225, 219, 212, 0.7);

		h1 {
			font-size: 1.75rem;
			line-height: 3.125rem;
			text-align: center;
			color: var(--font-color-main);
			margin-top: 0.625rem;
			text-transform: uppercase;
		}
	}

	.hero-header {
		display: block;
		width: 100%;
		z-index: 3;
		padding: 0 5px;
		height: 45px;


		.hero-text {
			text-align: center;
			width: 100%;
			padding: 0 15px;




		}

	}
}